import { status, api, redirect, lang, code, relay, appCode } from "./../app";
// global variables

import { initializeSiteSearch } from "./initializeSiteSearch";
import { request } from "./request";
import { dimmer } from "./dimmer";
import { trans } from "./trans";
import { notify } from "./notify";
import { humanDate } from "./humanDate";
import { marked } from "marked";
// helper function imports

let session,
	gacUser = {},
	user,
	author,
	noSessionResp = {
		gacUser: {},
		userLevel: "guest",
		relay: relay ? relay : ""
	};
window.handleAuth = async () => {
	const statusIndicator = $("#status_indicator");
	if (status != "prod") {
		statusIndicator.html("DEV SITE").removeClass("hidden");
	}
	const welcome = $(".welcome");
	const adminSignout = $("[data-button='admin sign out']").css({ position: "relative" });
	const signout = $("[data-button='sign out']").css({ position: "relative" });
	return new Promise(async resolve => {
		if (adminSignout.length) {
			adminSignout.on("click", async function () {
				adminSignout.append(dimmer(0.5));
				try {
					const response = await $.ajax({
						method: "GET",
						url: `${api}/logout`,
						xhrFields: {
							withCredentials: true
						}
					});
					adminSignout.find(".dimmer").remove();
					if (response.success) {
						notify(response.data, "success");
						window.location.href = `${location.origin}${location.pathname}`;
					} else {
						throw response.data;
					}
				} catch (err) {
					adminSignout.find(".dimmer").remove();
					console.error("Error logging out");
					notify("Error logging out");
				}
			});
		}
		// signout button click handler

		try {
			const response = await $.ajax({
				method: "POST",
				url: `${api}/request`,
				headers: {
					"Content-Type": "application/json"
				},
				xhrFields: {
					withCredentials: true
				},
				data: JSON.stringify({
					reqId: "189883db-5afc-4f61-a205-3558dbff688f"
					// "session2QueryViaCookiePublic"
				})
			});
			if (response.success) {
				if (response.data.session) {
					session = response.data.session;
					session.expires = session.expires * 1000;
					gacUser = session.user;
					author = `${gacUser.firstname} ${gacUser.lastname}`;
					signout.removeClass("hidden");
					welcome.html(`Welcome, ${gacUser.firstname}!`);
					if (!gacUser.access || !gacUser.access[appCode]) {
						// user not registered
						notify("You are logged in, but not registered to use this application. Please request access from Digital Marketing.", "danger");
						resolve({
							userLevel: "guest",
							gacUser: {},
							relay: relay ? relay : ""
						});
					} else {
						// user registered
						loginSucceeded("admin", gacUser);
					}
				}
			} else {
				// handled error in request
				throw response.data;
			}
		} catch (err) {
			// session error; need to sign back in
			console.error("There was a sign in error");
			location.href = "/en/admin/";
		}

		if (code) {
			// we are exchanging AWS Cognito user pool
			// code for token
			const params = { reqId: "9d5120ce-bed1-4c6d-9fc9-ed8aca6a8b14", code: code, redirect: redirect };
			try {
				const response = await request(params);
				let session = JSON.parse(response);
				if (session.user) {
					localStorage.setItem("gnews", JSON.stringify(session));
					user = session.user;
					loginSucceeded("user", session);
				} else {
					// session error
					throw trans("Could not log you in. Please try again.");
				}
			} catch (err) {
				loginFailed(trans("Could not log you in. Please try again."));
				// handle server error
			}
		} else if (localStorage.gnews) {
			const session = JSON.parse(localStorage.getItem("gnews"));
			try {
				const response = await request({
					reqId: "db6c929d-44b7-4bb0-a61d-5b46a6fcc0ed",
					access_token: session.token.access_token
				});
				user = response;
				loginSucceeded("user", { user: user });
			} catch (err) {
				console.error("error:", err);
				loginFailed(trans("Could not log you in. Please try again."));
			}
		} else {
			// neither valid session nor gnews key? definitely not signed in
			signout.hide();
			initializeSiteSearch();
			resolve({
				userLevel: "guest",
				gacUser: {},
				relay: relay ? relay : ""
			});
		}

		async function loginSucceeded(type, data) {
			if (type === "user") {
				if (data.error) {
					loginFailed(trans("Could not log you in. Please try again."));
				} else {
					welcome.html(`${trans("Welcome")} ${data.user.name}`);
					signout.show();
					let signOutLink = signout.attr("href");
					signout.attr("href", `${signOutLink}${redirect}`);
					signout.on("click", function () {
						signout.append(dimmer(0.5));
						localStorage.removeItem("gnews");
					});
					// set up signout link for trusted users
					initializeSiteSearch();
					resolve({
						userLevel: "guest",
						gacUser: gacUser,
						relay: relay ? relay : "",
						user: user
					});
				}
			} else if (type === "admin" || type === "qa") {
				localStorage.removeItem("gnews");
				welcome.html(`${trans("Welcome")} ${gacUser.firstname} (<span class="uk-position-relative pointer expire_time"></span>)`);
				const timerWrapper = $(".expire_time").on("click", function () {
					extendSession($(this));
				});
				const timer = setInterval(() => {
					let timeLeft = session.expires - new Date().getTime();
					timerWrapper.html(`${humanDate(session.expires, false, null, "T")}`);
					if (timeLeft > 0 && timeLeft <= 1000 * 60 * 10) {
						// less than 10 minutes remaining
						timerWrapper.addClass("text-utility-red");
						if (!timerWrapper.hasClass("warned")) {
							timerWrapper.addClass("warned");
							UIkit.modal.alert(`<h4>Session warning!</h4>
							<p>Your session is nearly expired. If you need more time, click the expire time shown next to your name to add another hour.</p>`);
						}
					} else if (timeLeft <= 0) {
						$(".expire_time").html("Expired");
						$("#main").css({
							opacity: 0.5
						});
						signout.hide();
						$("#mode_wrapper").hide();
					}
				}, 1000);
				adminSignout.show();
				initializeSiteSearch();
				let mediaAdminLinks;
				if (type === "admin") {
					mediaAdminLinks = $(`
				<li>
                    <a id="manageDocsLink">Manage Documents</a>
                </li>
				<li>
                    <a href="/en/orders/">Manage Media Requests</a>
                </li>
                <li>
                    <a uk-toggle="#userModal">Manage Users</a>
                </li>`);
					if ($("#lang_li").length) {
						mediaAdminLinks.insertBefore($("#lang_li"));
					} else {
						$("#menu ul").append(mediaAdminLinks);
					}
					// admins get links to the orders pg and
					// user mgmnt modal

					$("#manageDocsLink").on("click", function () {
						const { manageDocs } = require("./manageDocs");
						// bring in doc mgmt functions

						manageDocs.init(author);
					});
					const { manageUsers } = require("./manageUsers");
					manageUsers.init();
					// bring in user management functions and
					// prep user modal

					if (!$("#admin_help_link").length) {
						setTimeout(async () => {
							try {
								const response = await fetch("/dist/readme.md");
								let text = await response.text();
								text = marked(text);
								$("#helpModalBody").html(text);
								const version = $(text).find(".versionId").eq(0).text();
								statusIndicator.append(` ${version}`);
								if ($(window).width() > 600) {
									$("#right_nav .admin_welcome").append(`<span data-button id="admin_help_link" uk-icon="icon: question; ratio: .8"></span>`);
								} else {
									$("#menu .uk-list").append(`<li>
                            <span id="admin_help_link">Admin help</span>
                        </li>`);
								}
								$("#admin_help_link").on("click", function () {
									UIkit.modal("#helpModal").show();
								});
								// admin link help click handler
							} catch (err) {
								console.error("Error fetching Help content", err);
								notify(`Error fetching Help content: ${err}`, "danger");
							}
						}, 400);
					}
					// pull in readme and run through 'marked' package to create a nice
					// help file on the help modal.
				}
				resolve({
					userLevel: type,
					gacUser: gacUser,
					relay: relay ? relay : ""
				});
			}
		}
		// loginSucceeded function

		async function extendSession(button) {
			try {
				button.append(dimmer());
				const response = await $.ajax({
					method: "POST",
					url: `${api}/request`,
					headers: {
						"Content-Type": "application/json"
					},
					xhrFields: {
						withCredentials: true
					},
					data: JSON.stringify({
						reqId: "6ee7068f-7dc9-4075-8ccc-1d8ef3eaa7dc"
					})
				});
				button.find(".dimmer").remove();
				if (response.success) {
					session.expires = response.data;
				} else throw response.data;
			} catch (err) {
				button.find(".dimmer").remove();
				console.error(err);
				notify("Error extending session", "danger");
			}
		}

		function loginFailed(err, expired) {
			notify(err, "danger");
			history.replaceState({}, "", `${location.pathname}`);
			localStorage.removeItem("gnews");
			initializeSiteSearch();
			if (expired) {
				setTimeout(() => {
					location.href = `${api}/login?RelayState=${location.origin}/${lang}/${relay ? relay : ""}`;
				}, 1000);
			} else {
				resolve(noSessionResp);
			}
		}
	});
};
// end auth handler
