import { api, status } from "../app";
import { dimmer } from "./dimmer";

export const request = async (params, context = $("body"), data = []) => {
	return new Promise(async (resolve, reject) => {
		params.env = status;
		// note that env status is injected iinto data above

		try {
			context.append(dimmer());
			const reqParams = {
				method: "POST",
				url: `${api}/request`,
				headers: {
					"Content-Type": "application/json"
				},
				xhrFields: {
					withCredentials: true
				},
				data: JSON.stringify(params)
			};

			const response = await $.ajax(reqParams);
			context.find(".dimmer").remove();

			if (response.success) {
				if (response.data && response.data.Items) {
					data = data.concat(response.data.Items);
				} else if (response.data && response.data.Item) {
					data = response.data.Item;
				} else data = response.data;
				if (response.data && response.data.LastEvaluatedKey) {
					params.startkey = response.data.LastEvaluatedKey;
					resolve(request(params, context, data));
				} else {
					resolve(data);
				}
			} else throw response.data;
		} catch (err) {
			context.find(".dimmer").remove();
			console.error("Error in request");
			reject(err);
		}
	});
};
// end request function
