import { notify } from "./notify";
import { humanDate } from "./humanDate";
import { request } from "./request";
import { cModal, modal } from "./modal";

let users = [];
let users_proc, uModal, addUser, cancelEdit, confirmEdit;
export const manageUsers = {
	init: () => {
		// set up initial conditions of modal

		uModal = $("#userModalBody");
		uModal.append(`<div id="user_actions" class="uk-margin-bottom"><span id="add_user_button" style="cursor: pointer;" uk-icon="icon: plus-circle; ratio: 1.5"></span><span id="confirm_edit_button" style="cursor: pointer;" uk-icon="icon: check; ratio: 1.5"></span><span id="cancel_edit_button" style="cursor: pointer;" uk-icon="icon: close; ratio: 1.5"></span></div>
            <div id="edit_user_wrapper" class="uk-margin">
            </div>
            <div id="user_table_wrapper" class="uk-margin-bottom uk-overflow-auto">
            </div>`);
		confirmEdit = $("#confirm_edit_button").hide();
		cancelEdit = $("#cancel_edit_button").hide();
		addUser = $("#add_user_button").on("click", function () {
			manageUsers.editUser("");
		});
		manageUsers.getUsers();
	},
	command: (action, params = "", context = uModal || "") => {
		return new Promise(async (resolve, reject) => {
			try {
				let response = await request(
					{
						reqId: "d5bd2d69-ec23-448d-893e-db4a32a0defd",
						action: action,
						params: params
					},
					context
				);
				try {
					response = JSON.parse(response);
					resolve(response);
				} catch (err) {
					notify("Could not parse response", "danger");
					throw err;
				}
			} catch (err) {
				reject(`'${action}' error: ${err ? err : "Unknown"}`);
			}
		});
	},

	getUsers: async () => {
		// who are our trusted partners?

		try {
			let response = await manageUsers.command("list users");
			if (response.Users) {
				users = response.Users;
			} else {
				notify("No users found");
				throw "response missing Users property";
			}

			users_proc = users.map(user => {
				let map = {};
				map.status = user.UserStatus;
				map.enabled = user.Enabled;
				map.added = new Date(user.UserCreateDate).getTime();
				map.updated = new Date(user.UserLastModifiedDate).getTime();
				user.Attributes.forEach(attr => {
					map[attr.Name] = attr.Value;
				});
				return map;
			});
			users_proc = users_proc.sort((a, b) => {
				if (a.family_name < b.family_name) {
					return -1;
				} else if (a.family_name > b.family_name) {
					return 1;
				}
				return 0;
			});
			// put users in asc order by last name
			// (Cognito uses 'family_name')

			manageUsers.listUsers();
		} catch (err) {
			notify("Error getting users", "danger");
			console.error(err);
		}
	},

	listUsers: () => {
		// show users we have obtained

		$("#user_table_wrapper").html(`<table id="user_table" class="uk-table uk-table-striped uk-table-hover uk-table-small">
            <thead>
                <tr>
                    <th>Actions</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>`);
		const listTable = $("#user_table");
		listTable.find("tbody tr").remove();
		// empty tbody rows to start fresh

		users_proc.forEach(user => {
			let name = `${user.name ? `${user.name} ` : ""}${user.family_name ? user.family_name : ""}`;
			listTable.find("tbody").append(`<tr data-id="${user.sub}">
                <td><span class="user_info_button" uk-tooltip="pos: right; container: #user_table; title: ID: ${user.sub}<br/>Created: ${humanDate(user.added)}<br/>Updated: ${humanDate(user.updated)};" uk-icon="icon: info"></span> <span class="delete_user_button" style="cursor: pointer;" uk-tooltip="pos: right; container: #user_table; title: Delete user" uk-icon="icon: trash"></span> <span class="edit_user_button" style="cursor: pointer;" uk-icon="icon: pencil" uk-tooltip="pos: right; container: #user_table; title: Update user"></span> <span class="disable_user_button text-utility-${user.enabled ? "green" : "orange"}" style="cursor: pointer;" uk-icon="icon: ban" uk-tooltip="pos: right; container: #user_table; title: Disable/Enable user"></span>${
				user.status.toLowerCase() === "force_change_password" ? ` <span uk-tooltip="pos: right; container: #user_table; title: Resend account invitation email" style="vertical-align: middle;" class="resend_button pointer text-utility-orange" uk-icon="icon: mail"></span>` : ""
			} <span data-name="${name}" data-email="${user.email}" uk-tooltip="pos:right; container: #user_table; title: View downloads" style="vertical-align: middle;" class="download_report_button pointer" uk-icon="list"></span></td>
                <td>${name}</td>
                <td>${user.email ? `<a href="mailto:${user.email}">${user.email}</a>` : ""}</td>
                <td>${user.phone_number ? `<a href="tel:${user.phone_number}">${user.phone_number}</a>` : ""}</td>
                <td>${user.status}</td>
            </tr>`);
		});
		// get users onto table

		$(".delete_user_button").on("click", function () {
			manageUsers.deleteUser($(this).parents("tr").attr("data-id"));
		});

		$(".edit_user_button").on("click", function () {
			manageUsers.editUser($(this).parents("tr").attr("data-id"));
		});

		$(".disable_user_button").on("click", function () {
			manageUsers.toggleUser($(this).parents("tr").attr("data-id"));
		});

		$(".resend_button").on("click", async function () {
			let id = $(this).parents("tr").attr("data-id");
			let cModal = UIkit.modal.confirm(`This will send a new account invitation to <span class="bold">${users_proc.find(user => user.sub === id).email}</span>. Continue?`);
			cModal.dialog.stack = true;
			try {
				await cModal;
				manageUsers.resendUserInvite(id);
			} catch (err) {
				/* */
			}
		});

		$(".download_report_button").on("click", async function () {
			let email = $(this).attr("data-email");
			let name = $(this).attr("data-name");
			try {
				let downloads = await request({
					reqId: "c6148d32-343e-4918-b0fb-53982f7412e0",
					datatype: "download",
					filter: "#user.#email = :email or #user.#uid = :email",
					namesAdd: {
						"#email": "email",
						"#uid": "uid",
						"#user": "user"
					},
					valuesAdd: {
						":email": email
					}
				});
				if (downloads.length) {
					downloads = downloads.sort((a, b) => {
						if (a.time > b.time) {
							return 1;
						} else if (a.time < b.time) {
							return -1;
						} else return 0;
					});
					// default sort is descending by timestamp

					const downloadGroups = [];
					downloads.forEach(dl => {
						let date = humanDate(dl.time);
						let group = downloadGroups.find(group => group.date === date);
						if (group) {
							// a subarray for this date exists

							group.downloads.push(dl);
						} else {
							downloadGroups.push({
								date: date,
								downloads: [dl]
							});
						}
					});
					// now organize downloads by date

					modal(`<div class="standard_modal">
                        <div class="uk-modal-header">    
                            <h2 class="uk-modal-title">Media Assets Download Report For <span class="bold">${name}</span></h2>
                        </div>
                        <div class="uk-padding-small"><p><span class="bold">Email:</span> <a href="mailto:${email}">${email}</a><br/><span class="bold">Count:</span> ${downloads.length} download${downloads.length != 1 ? "s" : ""} on ${downloadGroups.length} date${downloadGroups.length != 1 ? "s" : ""}</p></div>
							<ul uk-tab>
								${downloadGroups.map(group => `<li><a>${group.date}</a></li>`).join("")}
							</ul>
							<ul class="uk-switcher">
								${downloadGroups
									.map(
										group => `
									<li>
			                            <table class="uk-table uk-table-striped uk-table-hover uk-table-small">
			                                <thead>
			                                    <tr>
			                                        <th>Details</th>
			                                        <th>Time</th>
			                                        <th>Thumb</th>
			                                    </tr>
			                                </thead>
			                                <tbody>
												${group.downloads
													.map(item => {
														const date = new Date(item.time);
														const hours = date.getHours();
														const mins = date.getMinutes();
														const time = `${hours < 13 ? hours : hours - 12}:${mins < 10 ? `0${mins}` : mins} ${hours < 12 ? "am" : "pm"}`;
														return `
												<tr>
			                                        <td><span class="bold">Name:</span> ${item.item.name}<br/><span class="bold">Filename:</span> ${item.item.filename}<br/><span class="bold">Created:</span> ${item.item.created}<br/><span class="bold">Category:</span> ${item.item.locator.value}<br/><span class="bold">Type/Size:</span> ${item.item.type}/${item.item.size / 1000} MB</td>
			                                        <td>${time}</td>
			                                        <td><img class="download_thumb" onError="$(this).attr('src','${item.item.thumb}')" src="${item.item.url}" width="200" height="auto"/></td>
			                                    </tr>`;
													})
													.join("")}
			                                </tbody>
			                            </table>
									</li>`
									)
									.join("")}
								</ul>
	                    </div>`);
				} else {
					modal(`<div class="standard_modal">
                        <div class="uk-modal-header">    
                            <h2 class="uk-modal-title">Media Assets Download Report For <span class="bold">${name}</span></h2>
                        </div>
							<div class="uk-padding-small"><p>No downloads found</p>
						</div>
						</div>`);
				}
			} catch (err) {
				console.error(`Error fetching user downloads: ${err}`);
				notify(`Error fetching user downloads`, "danger");
			}
		});
	},

	deleteUser: async id => {
		// remove user

		try {
			await cModal();
			await manageUsers.command("delete user", { Username: id });
			manageUsers.getUsers();
		} catch (err) {
			if (err) {
				console.error(err);
				notify("Error deleting user", "danger");
			}
		}
	},

	toggleUser: async id => {
		// enable/disable user

		let user = users_proc.find(user => user.sub === id);
		let enabled = user.enabled ? true : false;
		try {
			await cModal();
			try {
				await manageUsers.command(`${users_proc.find(user => user.sub === id).enabled ? "disable user" : "enable user"}`, {
					Username: id
				});
				manageUsers.getUsers();
			} catch (err) {
				notify("Error enabling/disabling user", "danger");
				throw err;
			}
			if (enabled) {
				// effect of toggle is to disable user,
				// so need to do a global sign out

				try {
					await manageUsers.command("user global sign out", { Username: id });
					notify("User is signed out of all sessions", "success");
				} catch (err) {
					notify("Error signing out user", "danger");
					throw err;
				}
			}
		} catch (err) {
			if (err) {
				console.error(err);
			} else {
				console.error("Unknown error toggling user access");
			}
		}
	},
	// end toggleUser method

	resendUserInvite: async id => {
		let user = users_proc.find(user => user.sub === id);
		try {
			await manageUsers.command(
				"add_user",
				{
					UserAttributes: [
						{
							Name: "name",
							Value: user.name
						},
						{
							Name: "family_name",
							Value: user.family_name
						},
						{
							Name: "email",
							Value: user.email
						},
						{
							Name: "phone_number",
							Value: user.phone_number ? user.phone_number : ""
						},
						{
							Name: "email_verified",
							Value: "True"
						}
					],
					MessageAction: "RESEND",
					Username: user.email,
					DesiredDeliveryMediums: ["EMAIL"],
					ForceAliasCreation: true
				},
				$(`tr[data-id='${id}']`)
			);
		} catch (err) {
			notify("There was an error resending invite", "danger");
			console.error("Error recreating user in resendUserInvite:", err);
		}
	},
	// end resendUserInvite method

	editUser: id => {
		// edit (or add) a user

		addUser.hide();
		confirmEdit.show();
		cancelEdit.show();

		cancelEdit.off("click").on("click", function () {
			addUser.show();
			confirmEdit.hide();
			cancelEdit.hide();
			$("#edit_user_wrapper").html("");
		});
		// no reason not to set cancel click handler now

		let user = id
			? users_proc.find(item => item.sub === id)
			: {
					added: new Date().getTime(),
					email: "",
					email_verified: "True",
					name: "",
					phone_number: "",
					phone_number_verified: "False"
			  };
		$("#edit_user_wrapper").html(`<form id="user_form" enctype="multipart/form-data" autocomplete="off" uk-grid>
            <h3 class="uk-width-1-1">${id ? "Edit" : "Add"} User Form</h3>
            ${id ? `<div class="uk-margin-small-top uk-margin-remove-bottom uk-width-1-1 text-grey5">ID: ${id}</div>` : ""}
                <div class="field uk-width-1-1 uk-width-1-4@s">
                    <label for="name">First Name*</label>
                    <input class="uk-input" type="text" name="name" placeholder="First name" value="${user.name ? user.name : ""}">
                </div>
                <div class="field uk-width-1-1 uk-width-1-4@s">
                    <label for="family_name">Last Name*</label>
                    <input class="uk-input" type="text" name="family_name" placeholder="Last name" value="${user.family_name ? user.family_name : ""}">
                </div>
                <div class="field uk-width-1-1 uk-width-1-4@s">
                    <label for="email">Email*</label>
                    <input class="uk-input" type="text" name="email" placeholder="Email" value="${user.email ? user.email : ""}">
                </div>
                <div class="field uk-width-1-1 uk-width-1-4@s">
                    <label for="phone_number">Phone</label>
                    <input class="uk-input" type="text" name="phone_number" placeholder="Phone" value="${user.phone_number ? user.phone_number : ""}">
                    <div class="uk-text-small">Phone must have no spaces, parentheses or hyphens, and must begin with a country code. Example: <span class="bold">+19876543210</span></div>
                </div>
                <input type="hidden" name="username" value="${id ? id : ""}">
            </form>`);
		// generate form

		let form = $("#user_form");
		form.find(".field").removeClass("error");
		form.find("input").on("input", function () {
			$(this).parents(".field").removeClass("error");
		});
		// clear errors on form build and during input

		confirmEdit.off("click").on("click", async function () {
			let valid = true;
			form.find("[name]").each(function () {
				let field = $(this).attr("name");
				if (field === "name" || field === "family_name" || field === "email") {
					if (!$(this).val()) {
						$(this).parents(".field").addClass("error");
						valid = false;
					}
				}
			});
			if (valid) {
				let phone_number = form.find("[name='phone_number']").val();
				let params = {
					UserAttributes: [
						{
							Name: "name",
							Value: form.find("[name='name']").val()
						},
						{
							Name: "family_name",
							Value: form.find("[name='family_name']").val()
						},
						{
							Name: "email",
							Value: form.find("[name='email']").val()
						},
						{
							Name: "phone_number",
							Value: phone_number
						},
						{
							Name: "phone_number_verified",
							Value: phone_number ? "True" : "False"
						},
						{
							Name: "email_verified",
							Value: "True"
						}
					],
					Username: `${form.find("[name='username']").val() ? form.find("[name='username']").val() : form.find("[name='email']").val()}`
				};
				if (!id) {
					params.DesiredDeliveryMediums = ["EMAIL"];
					params.ForceAliasCreation = true;
				}
				try {
					const response = await manageUsers.command(id ? "update user" : "add user", params);
					form.remove();
					addUser.show();
					confirmEdit.hide();
					cancelEdit.hide();
					manageUsers.getUsers();
				} catch (err) {
					console.error(err);
				}
			}
		});
	}
};
// end manageUsers object
