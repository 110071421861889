export const notify = (message, status = "primary", pos = "top-right", timeout = 3000, group, closeGroup, close) => {
	if (close) {
		UIkit.notification.closeAll(closeGroup ? closeGroup : "");
	}
	// if close is true but no closeGroup, close all;
	// if closeGroup passed in, close just those

	UIkit.notification({
		message: message,
		status: status,
		pos: pos,
		timeout: timeout,
		group: group
	});
};
// messaging via UIkit notify component
