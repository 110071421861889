export const modal = (content, stack = true, large = true, dialogScroll = false, closeBtn = true, bgClose = true) => {
	const modal = $(UIkit.modal.dialog(content).$el);
	let dialog = modal.find(".uk-modal-dialog").addClass("uk-modal-body");
	modal.attr("uk-modal", `stack: ${stack}; bg-close: ${bgClose}`);
	if (large) {
		// default is true
		modal.addClass("uk-flex-top uk-modal-container");
	}
	if (closeBtn) {
		// default is true
		dialog.prepend(`<button class="uk-modal-close-default" type="button" uk-close></button>`);
	}
	if (dialogScroll) {
		// default is false
		dialog.find(".uk-modal-dialog").attr("uk-overflow-auto", "");
	}
	return modal;
};
// end modal helper function

export const cModal = (content = "Are you sure?") => {
	return new Promise(async (resolve, reject) => {
		let cModal = UIkit.modal.confirm(content);
		cModal.dialog.stack = true;
		try {
			await cModal;
			resolve();
		} catch (err) {
			reject();
		}
	});
};
// end confirm modal helper function
