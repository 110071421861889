import { dimmer } from "./dimmer";
import { notify } from "./notify";
import { api, assetHost } from "../app";

export const uploadInit = options => {
	let bar = options.progressBar ? options.progressBar.hide() : false;
	let uploaded = [];
	UIkit.upload(options.dropZone, {
		url: options.url ? options.url : `${api}/s3/binary`,
		multiple: options.multiple,
		name: "files",
		mime: options.type,
		allow: options.allow,
		maxSize: options.maxSize, // KB
		beforeSend: req => {
			req.method = "POST";
			req.xhr.withCredentials = true;
			req.responseType = "json";
			let fileData = req.data.get("files");
			const sanitizedName = fileData.name.replace(/[\+\s]/g, "-");
			req.data = new FormData();
			for (let key in options.postOptions) {
				req.data.append(key, options.postOptions[key]);
			}
			req.data.append("files", fileData, sanitizedName);
		},
		load: options.load ? options.load : () => {},
		complete: xhr => {
			options.dimmerParent.find(".dimmer").remove();
			let response = typeof xhr.response === "string" ? JSON.parse(xhr.response) : xhr.response;
			if (response.success) {
				let _asset = response.data.files[0];
				_asset.location = `${assetHost}/${_asset.key}`;
				if (response.data.thumbStatus) {
					if (response.data.thumbStatus != "No errors") {
						console.error(response.data.thumbStatus.join("; "));
						notify(response.data.thumbStatus.join("; "), "danger", "top-right", 3000, "uploads");
					} else {
						if (response.data.thumbnails.length) {
							_asset.thumb = response.data.thumbnails[0];
						} else _asset.thumb = "";
					}
				}
				uploaded.push(
					Object.assign(_asset, {
						uploadTime: new Date().getTime()
					})
				);
				if (!options.silent) {
					notify(`Saved "${_asset.originalname}" to database.`, "success", "top-right", 3000, "uploads");
				}
			} else {
				console.error(response.data);
				notify(`Could not save images to our server`, "danger", "top-right", 3000, "uploads");
			}
		},
		loadStart: e => {
			options.dimmerParent.append(dimmer(options.dimmerSize));
			if (bar) {
				bar.show();
				bar.attr({
					max: e.total,
					value: e.loaded
				});
			}
		},
		progress: e => {
			if (bar) {
				bar.attr({
					max: e.total,
					value: e.loaded
				});
			}
		},
		loadEnd: e => {
			if (bar) {
				bar.attr({
					max: e.total,
					value: e.loaded
				});
			}
		},
		completeAll: req => {
			let response = typeof req.response === "string" ? JSON.parse(req.response) : req.response;
			if (response.success) {
				if (bar) {
					setTimeout(() => {
						bar.hide();
					}, 500);
				}
				if (!options.silent) {
					notify("All uploads completed", "success", "top-right", 3000, "uploads", true);
				}
				options.onComplete(uploaded);
			} else {
				console.error(response.data);
				notify(`There was an error: ${response.data}`, "danger", "top-right", 3000, "uploads", true);
			}
		},
		error: err => {
			console.error("Upload error:", err);
		},
		fail: err => {
			console.error(err);
			notify(err, "danger");
		},
		msgInvalidName: options.badTypeErr,
		msgInvalidMime: options.badMIMEErr,
		msgInvalidSize: options.badSizeErr
	});
	// initialize drop area for file uploads
};
