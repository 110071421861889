export const initializeSiteSearch = () => {
    $("#site_search").on("submit", function (e) {
        e.preventDefault();
        let input = $(this).find("input").val().trim();
        let terms = [];
        let raw = input.split("\"");
        if (raw.length > 1) {
            parser(raw);
        }
        else {
            raw = input.split("'");
            if (raw.length > 1) {
                parser(raw);
            }
            else {
                terms = raw[0].trim().split(" ");
            }
        }
        function parser(arr) {
            arr = arr.filter(term => term != "").map(term => {
                if (term.charAt(0) === " " || term.charAt(term.length - 1) === " ") {
                    // not a phrase
                    return term.trim().split(" ");
                }
                return term;
            });
            for (let i = 0; i < arr.length; i++) {
                if (typeof arr[i] === "object") {
                    terms = terms.concat(arr[i]);
                }
                else terms.push(arr[i]);
            }
        };
        // end parser function
    })
};
// end initializeSiteSearch function