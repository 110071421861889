import { lang } from "./../app";

// see Readme under Development section
// for steps on adding new translation supplements

const pageTransMap = {
	about: typeof about_translations != "undefined" ? about_translations : {},
	aircraft: typeof aircraft_translations != "undefined" ? aircraft_translations : {},
	contact: typeof contact_translations != "undefined" ? contact_translations : {},
	history: typeof history_translations != "undefined" ? history_translations : {},
	media: typeof media_translations != "undefined" ? media_translations : {},
	orders: typeof orders_translations != "undefined" ? orders_translations : {},
	kits: typeof kits_translations != "undefined" ? kits_translations : {},
	responsibility: typeof responsibility_translations != "undefined" ? responsibility_translations : {}
};
// if the page has imported a particular subset of translations,
// adding this map will allow us to export it as part of the
// main translations object

const addTranslations = translations => {
	for (let page in pageTransMap) {
		if (location.pathname.indexOf(page) > -1) {
			translations = Object.assign(translations, pageTransMap[page]);
		}
	}
};
addTranslations(translations);
// page-based additonal translations added

export const trans = (str, langOverride) => {
	let transKey = str.toLowerCase().replace(/ /g, "_");
	return translations[transKey] && translations[transKey][lang] ? translations[transKey][langOverride ? langOverride : lang] : translations[transKey] && translations[transKey].en ? translations[transKey].en : str;
};
