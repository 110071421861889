import { trans } from "./trans";
import { lang } from "./../app";

export const humanDate = (timestamp, includeTime = false, joiner = ", ", componentCode, override_lang = false) => {
	let date = new Date(timestamp);
	let _date;
	function getTime() {
		let hours = date.getHours();
		let mins = date.getMinutes();
		return `${!hours ? "12" : hours < 13 ? hours : hours - 12}:${mins < 10 ? `0${mins}` : mins} ${hours < 12 ? "am" : "pm"}`;
	}
	let target_lang = override_lang ? override_lang : lang;
	if (componentCode) {
		switch (componentCode) {
			case "Y":
				_date = trans("date_format_year", target_lang).replace(/\{y\}/, date.getFullYear());
				break;
			case "MY":
				let month = date.getMonth();
				month = target_lang === "ru" ? month + 12 : month;
				// special handling for Russian months
				// when no dates are included

				_date = trans("date_format_year_month", target_lang).replace(/\{m\}/, trans("months", target_lang)[month]).replace(/\{y\}/, date.getFullYear());
				break;
			case "T":
				// time element only
				_date = getTime();
				break;
			default:
				_date = trans("date_format", target_lang).replace(/\{m\}/, trans("months", target_lang)[date.getMonth()]).replace(/\{d\}/, date.getDate()).replace(/\{y\}/, date.getFullYear());
		}
	} else {
		_date = trans("date_format", target_lang).replace(/\{m\}/, trans("months", target_lang)[date.getMonth()]).replace(/\{d\}/, date.getDate()).replace(/\{y\}/, date.getFullYear());
	}
	if (includeTime) {
		let hours = date.getHours();
		let mins = date.getMinutes();
		let time = `${hours < 13 ? hours : hours - 12}:${mins < 10 ? `0${mins}` : mins} ${hours < 12 ? "am" : "pm"}`;
		return `${_date}${joiner}${time}`;
	}
	return _date;
};
