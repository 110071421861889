import { languages, placeholder, assetHost } from "../app";
import { notify } from "./notify";
import { humanDate } from "./humanDate";
import { request } from "./request";
import { cModal, modal } from "./modal";
import { uploadInit } from "./uploadInit";
import { v4 as uuid } from "uuid";
let docs = [];
let docModal, modalBody, docListWrapper, firstTouch, origPages;
// const invalidationParams = {
// 	action: "CreateInvalidation",
// 	params: {
// 		DistributionId: "EIQONYATJLYCL",
// 		InvalidationBatch: {
// 			CallerReference: new Date().getTime(),
// 			Paths: {
// 				Quantity: 1,
// 				Items: [`/backgrounders${""
// 			}
// 		}
// 	}
// };
// for keeping backgrounder feed updated with doc edits/deletes
export const manageDocs = {
	init: author => {
		// set up initial conditions of modal
		docModal = modal(`<div class="uk-modal-header">
				<h2 class="uk-modal-title">Document Manager</h2>
			</div>
			<div class="uk-position-relative" id="docModalBody">
				<div class="uk-margin uk-margin-small-top">
					<span class="pointer" uk-icon="icon: plus-circle; ratio: 2" id="add_docs_button"></span> Add new
				</div>
				<div class="uk-width-1-1 uk-margin">
					<label for="doc_filter">Filter</label>
					<div class="field">
						<input class="uk-input uk-margin-small-right" name="doc_filter" type="text" placeholder="Find this text">
					</div>
					<div class="uk-text-small">TIP: No need to hit "Return" after entering text; results will be presented automatically once you cease typing</div>
				</div>
				<div id="document_list_wrapper" uk-grid class="uk-margin-remove uk-width-1-1 uk-grid-small uk-child-width-1-1 uk-child-width-1-4@s"></div>
                        

			</div>`);
		modalBody = docModal.find("#docModalBody");
		docListWrapper = docModal.find("#document_list_wrapper");
		let inputTimer;
		$("input[name='doc_filter']").on("input", function () {
			clearTimeout(inputTimer);
			let docWrappers = $(".document_wrapper");
			// reset input timer

			let term = $(this).val();
			if (term) {
				term = term.toLowerCase();
				inputTimer = setTimeout(() => {
					// start with all hidden

					docWrappers.addClass("hidden");
					$(".document_wrapper").each(function () {
						if ($(this).text().toLowerCase().indexOf(term) > -1) {
							$(this).removeClass("hidden");
						}
					});
					// reveal docs containing input term
				}, 500);
				// wait 2 seconds after input before
				// launching filter action
			} else {
				clearTimeout(inputTimer);
				docWrappers.removeClass("hidden");
				// no terms? show all
			}
		});
		// filter input handler

		$("#add_docs_button").on("click", function () {
			firstTouch = true;
			manageDocs.editDoc(author);
		});
		// add new doc button click handler

		manageDocs.getDocs(author);
	},
	// end init method

	getDocs: async author => {
		// who are our trusted partners?

		try {
			docs = await request(
				{
					reqId: "c6148d32-343e-4918-b0fb-53982f7412e0",
					datatype: "pdf"
				},
				docModal
			);
			if (docs.length) {
				manageDocs.sortDocs();
				manageDocs.listDocs(author);
			} else {
				modalBody.html("No documents found.");
			}
		} catch (err) {
			notify(`There was an error fetching documents`, "danger");
			console.error("Error fetching documents:", err);
		}
	},
	// end getDocs method

	sortDocs: () => {
		docs = docs.sort((a, b) => {
			let aVal, bVal;
			if (a.reference_name) {
				aVal = a.reference_name;
			} else if (a.langs) {
				const aKeys = Object.keys(a.langs);
				aVal = a.langs[aKeys[0]].filename;
			} else {
				aVal = "";
			}
			if (b.reference_name) {
				bVal = b.reference_name;
			} else if (b.langs) {
				const bKeys = Object.keys(b.langs);
				bVal = b.langs[bKeys[0]].filename;
			} else {
				bVal = "";
			}
			if (aVal > bVal) {
				return 1;
			} else if (aVal < bVal) {
				return -1;
			} else return 0;
		});
	},
	// end sortDocs method

	listDocs: author => {
		// show docs in the database

		docListWrapper.html("");
		// start from blanked list wrapper

		docs.forEach(doc => {
			const langs = Object.keys(doc.langs);
			// langs is an array of lang codes representing
			// the translations uploaded for this document

			const activeLang = langs.includes("en") ? "en" : langs[0];
			// active lang is 'en' if exists, or
			// whichever is first if not

			docListWrapper.append(`<div data-id="${doc.id}" class="document_wrapper uk-card uk-card-body uk-card-default">
				<div class="uk-card-title">${doc.reference_name ? doc.reference_name : "Name unavailable"}</div>	
				<div class="uk-text-small uk-margin-small">Document ID: ${doc.id}</div>
				<div>Available languages:</div>
				<table class="uk-table uk-table-small uk-table-striped uk-text-small">
					<thead>
						<th>Language</th>
						<th>By</th>
						<th>Date</th>
					</thead>
					<tbody>
						${langs
							.map(
								lang => `<tr>
							<td>${languages[lang]}</td>
							<td>${doc.langs[lang].updatedBy ? doc.langs[lang].updatedBy : "Unknown"}</td>
							<td>${doc.langs[lang].updated ? humanDate(doc.langs[lang].updated, true, ", ") : "Unknown"}</td>
						</tr>`
							)
							.join("")}
					</tbody>
				</table>
				<div class="document_buttons_wrapper">
					<span class="doc_edit_button pointer" uk-icon="pencil"></span>
					<span class="doc_delete_button text-utility-orange pointer" uk-icon="trash"></span>
				</div>
			</div>`);
		});
		// place docs on page

		$(".doc_edit_button").on("click", function () {
			firstTouch = true;
			manageDocs.editDoc(author, $(this).parents(".document_wrapper").attr("data-id"));
		});
		// doc_edit_button click handler

		$(".doc_delete_button").on("click", function () {
			manageDocs.deleteDoc(author, $(this).parents(".document_wrapper").attr("data-id"));
		});
		// doc_delete_button click handler
	},
	// end listDocs method

	editDoc: (author, id, doc, editLang, editor) => {
		const pages = [
			{
				name: "About",
				value: "about"
			},
			{
				name: "Corporate Responsibility",
				value: "cr"
			}
		];
		if (!doc) {
			doc = id
				? docs.find(item => item.id === id)
				: {
						type: "pdf",
						id: uuid(),
						langs: {},
						createdBy: author
				  };
		}
		if (!editLang) {
			editLang = doc.langs.en ? "en" : Object.keys(doc.langs).length ? Object.keys(doc.langs)[0] : "en";
		}
		// if editLang is not defined and we're editing,
		// it is "en" if "en" exists,
		// else it is the first lang found;
		// if none found, editLang defaults to "en"

		if (!doc.langs[editLang]) {
			// editing doc for a lang
			// version that doesn't yet exist

			doc.langs[editLang] = {
				name: "",
				lang: editLang,
				filename: "",
				link: "",
				createdBy: author,
				thumb: ""
			};
		}

		if (!editor) {
			editor = modal(`<div id="docEditorBody"></div>`);
		}
		editor.find("#docEditorBody").html(`<div ${id ? `data-id="${id}" ` : ""}class="uk-modal-header">
				<h2 class="uk-modal-title">Editing ${id ? `<strong>${doc.reference_name}</strong>` : "new document"}</h2>
			</div>
			<div>
				${
					id || doc.id
						? `<div class="uk-text-small uk-margin-bottom uk-margin-small-left">
					<p class="uk-margin-remove">ID: ${id ? id : doc.id ? doc.id : "Not assigned"}</p>
				</div>`
						: ""
				}
				<form uk-grid id="document_form" class="uk-grid-small uk-margin-remove uk-position-relative">
					<div class="field uk-margin-bottom uk-width-1-1 uk-width-1-3@s">
						<label for="language">Language</label>
						<select id="language" name="language" class="uk-width-1-1">
							${Object.keys(languages)
								.map(lang => `<option value="${lang}"${lang === editLang ? " selected" : ""}>${languages[lang]}</option>`)
								.join("")}
						</select>
					</div>
					<div class="field uk-margin-bottom uk-width-1-1 uk-width-1-3@s">
						<div>
							<label>&nbsp;</label>
							<button class="uk-button uk-button-danger" id="remove_lang_version">Remove ${languages[editLang]} version of this document</button>
						</div>
					</div>
					<div class="field uk-margin-bottom uk-width-1-1 uk-width-1-3@s">
						 <div class="uk-margin">
							<label class="uk-width-1-1">Automatically display on these pages?</label>
							<div uk-grid class="uk-grid-small uk-margin-remove uk-child-width-auto" style="min-height: 50px;">
								${pages.map((page, index) => `<div class="uk-padding-remove"><label style="font-weight: 100 !important;"><input name="autoList" class="uk-checkbox ${index ? "uk-margin-small-left" : ""}" type="checkbox" value="${page.value}" ${doc.autoList && doc.autoList.includes(page.value) ? "checked" : ""}> ${page.name}</label></div>`).join("")}
							</div>
							<div class="uk-width-1-1 uk-text-small"><span class="bold">NOTE:</span> This setting applies to ALL language versions</div>
						</div>
					</div>
					<div class="field uk-width-1-1 uk-width-1-2@s uk-margin-bottom">
						<label for="reference_name">Global document name</label>
						<input class="uk-input" name="reference_name" id="reference_name"${doc.reference_name ? ` value="${doc.reference_name}"` : ""}>
						<div class="uk-text-small"><span class="bold">NOTE: This value is not public-facing but applies to ALL language versions</div>
					</div>
					<div class="field uk-width-1-1 uk-width-1-2@s uk-margin-bottom">
						<label for="name">${languages[editLang]} document name</label>
						<input class="uk-input" name="name" id="name"${doc.langs[editLang] && doc.langs[editLang].name ? ` value="${doc.langs[editLang].name ? doc.langs[editLang].name : ""}"` : ""} placeholder="Language-specific name">
					</div>
					<div id="docUpload" class="field uk-margin-bottom js-upload uk-placeholder uk-text-center uk-width-1-1">
						<span uk-icon="icon: cloud-upload"></span>
						<span class="uk-text-middle">${id ? "Replace" : "Upload"} <span class="bold">${languages[editLang]}</span> document by dropping a PDF here, or </span>
						<div uk-form-custom>
							<input type="file">
							<span class="uk-link underline pointer">browse your filesystem</span>
						</div>
						<progress id="docProgress" class="uk-progress hidden" value="0" max="100"></progress>
					</div>
					<div class="field uk-width-1-1 uk-width-1-3@s uk-margin-bottom">
						<label>Thumbnail${doc.langs[editLang].thumb ? ` (click for preview)` : ""}</label>
						<div class="uk-cover-container uk-align-center doc_thumb">
							<img id="thumbnail"${doc.langs[editLang].thumb ? `class="previewable pointer" data-lang="${editLang}" data-name="${doc.reference_name}" data-url="${doc.langs[editLang].link}" src="${doc.langs[editLang].thumb}"` : `src="${placeholder}"`} uk-cover />
						</div>
					</div>
					<div class="uk-width-1-1 uk-width-2-3@s">
						<div class="field uk-margin-bottom">
							<label>Filename</label>
							<div id="filename" class="breakable">${doc.langs[editLang].filename ? doc.langs[editLang].filename : ""}</div>
						</div>
						<div class="field uk-margin-bottom">
							<label>Uploaded by</label>
							<div><span id="createdBy">${doc.langs[editLang].createdBy ? doc.langs[editLang].createdBy : "Author unknown"} <span id="uploaded">${doc.langs[editLang].uploaded ? ` (${humanDate(doc.langs[editLang].uploaded, true, " at ")})` : ""}</span></div>
						</div>
						<div class="field uk-margin-bottom">
							<label>Last update:</label>
							<div><span id="updatedBy">${doc.langs[editLang].updatedBy ? doc.langs[editLang].updatedBy : "Edit author unknown"} (<span id="updated">${doc.langs[editLang].updated ? `${humanDate(doc.langs[editLang].updated, true, " at ")}` : "No date recorded"}</span>)</div>
						</div>
						<div class="field uk-margin-bottom">
							<label>URL</label>
							<div class="breakable"><span id="link">${doc.langs[editLang].link ? doc.langs[editLang].link : ""}</span></div>
						</div>
					</div>
					<div class="editor_button_wrapper uk-width-1-1">
						<button id="doc_edit_submit" class="uk-button uk-button-primary">Submit</button>
						<button id="doc_edit_cancel" class="uk-button uk-button-primary uk-modal-close">Cancel</button>
					</div>
				</form>
			</div>`);
		// create modal form

		$("#remove_lang_version")
			.off("click")
			.on("click", async function (e) {
				e.preventDefault();
				try {
					await cModal(`<h2>Are you sure?</h2>
				<div>This action will remove the <strong>${languages[editLang]}-language</strong> version of this document, but not affect any other versions. To remove all versions of the document, click the red delete button at the bottom of the edit form.</div>`);
					delete doc.langs[editLang];
					manageDocs.editDoc(author, id, doc, null, editor);
				} catch (err) {
					if (err) {
						console.error("Error with confirmation modal:", err);
					}
				}
			});

		$("input").on("input", function () {
			$(this).parents(".field").removeClass("error");
		});
		// input in errored fields removes error class

		$("#language")
			.select2({
				minimumResultsForSearch: Infinity
			})
			.on("change", async function () {
				const now = new Date().getTime();
				const refNameInput = editor.find("[name='reference_name']");
				const nameInput = editor.find("[name='name']");

				if (doc.reference_name != refNameInput.val()) {
					doc.reference_name = refNameInput.val();
					doc.updated = now;
					doc.updatedBy = author;
				}

				if (firstTouch) {
					firstTouch = false;
					origPages = doc.autoList ? doc.autoList.join(",") : "";
				}
				doc.autoList = [];

				$("[name='autoList']:checked").each(function () {
					doc.autoList.push($(this).val());
				});

				if (origPages != doc.autoList.join(",")) {
					doc.updated = now;
					doc.updatedBy = author;
				}
				// track page assignment changes

				if (doc.langs[editLang].name != nameInput.val()) {
					doc.langs[editLang].name = nameInput.val();
					doc.langs[editLang].updated = now;
					doc.langs[editLang].updatedBy = author;
					doc.updated = now;
					doc.updatedBy = author;
				}
				// write doc 'reference_name', 'name'
				// update times/author
				// values and page assignments to in-memory
				// doc before moving on;
				// note that global update/updatedBy is
				// updated in all cases where change occured, and
				// lang-version values for lang-specific updates

				// Where are checks for file data? That is handled
				// in the upload configuration ("onComplete" fn)

				manageDocs.editDoc(author, id, doc, $(this).val(), editor);
			});

		// lang selector initialized

		const makePreviewable = thumb => {
			thumb.off().on("click", function () {
				modal(`<div class="uk-modal-header">
				<h2 class="uk-modal-title">Previewing ${languages[$(this).attr("data-lang")]}-language document <strong>${$(this).attr("data-name")}</strong></h2>			
				<div class="uk-modal-body documentPreview">
					 <iframe src="${$(this).attr("data-url")}" width="100%" height="100%" allowfullscreen webkitallowfullscreen></iframe>
				</div>`);
			});
		};
		$(".previewable").each(function () {
			makePreviewable($(this));
		});
		// thumbnail preview click handler

		uploadInit({
			postOptions: {
				bucket: "gulfstreamnews-data",
				key: "documents",
				tagging: encodeURI("dmSite=Gulfstream News&Developer=Kelly Davis&Name=PDF Document&Project=Document Manager"),
				storageclass: "INTELLIGENT_TIERING",
				acl: "public-read",
				thumb: JSON.stringify({
					type: "pdf",
					store: {
						keyPrefix: "images/thumbnails/pdfs",
						bucket: "gulfstreamnews-data",
						publicUrl: `${assetHost}/images/thumbnails/pdfs/`
					},
					options: {
						format: "png"
					}
				})
			},
			dropZone: $("#docUpload"),
			progressBar: $("#docProgress"),
			multiple: false,
			type: "application/pdf",
			allow: "*.pdf",
			badType: "Invalid file! Only PDFs are allowed here!",
			badMIME: "Invalid file! Only PDFs are allowed here!",
			badSize: "This file is too big! Files must be under 10MB.",
			maxSize: 50000, //KB
			dimmerParent: $("#docUpload"),
			dimmerSize: 1,
			silent: false,
			onComplete: uploads => {
				$("#docUpload").removeClass("error");
				const now = new Date().getTime();
				const upload = uploads[0];
				// there should only ever be one

				const formName = editor.find("[name='name']").val();
				const formRefName = editor.find("[name='reference_name']").val();
				const name = formName ? formName : doc.langs[editLang].name ? doc.langs[editLang].name : formRefName ? formRefName : doc.reference_name ? doc.reference_name : "Unnamed";
				// determine best name for preview title

				doc.langs[editLang] = Object.assign(doc.langs[editLang], {
					uploaded: doc.langs[editLang].uploaded ? doc.langs[editLang].uploaded : now,
					updatedBy: author,
					updated: now,
					filename: upload.originalname,
					lang: editLang,
					link: `${assetHost}/${upload.key}`,
					thumb: upload.thumb
				});
				doc.updated = now;
				doc.updatedBy = author;
				makePreviewable($("#thumbnail").attr({ src: upload.thumb, "data-lang": editLang, "data-url": upload.location, "data-name": name }).addClass("previewable pointer"));
				$("#filename").text(upload.originalname);
				$("#link").text(`${assetHost}/${upload.key}`).parents(".field").removeClass("error");
				$("#createdBy").text(doc.langs[editLang].createdBy);
				$("#uploaded").text(doc.langs[editLang].uploaded);
				$("#updatedBy").text(doc.langs[editLang].updatedBy);
				$("#updated").text(doc.langs[editLang].updated);
				// update DOM
			} // callback for all complete
		});
		// set up doc uploader

		$("#doc_edit_submit").on("click", async function (e) {
			e.preventDefault();
			const form = $(this).parents("form");
			const errors = {};
			form.find(".error").removeClass("error");
			const required = ["reference_name", "name", "link"];
			const reqHumanNames = ["Global name", "Name", "Document"];
			let valid = true;
			let input;
			for (let lang in doc.langs) {
				required.forEach((reqField, index) => {
					switch (reqField) {
						case "link":
							// special handling for non-input values

							if (!doc.langs[lang].link) {
								valid = false;
								const msg = `The <strong>${reqHumanNames[index]}</strong> is missing from the <strong>${languages[lang]}</strong> version.`;
								if (errors[lang]) {
									errors[lang].push(msg);
								} else {
									errors[lang] = [msg];
								}

								if (editLang === lang) {
									// is the current editor for this lang?

									$("#docUpload").addClass("error");
									// we can put a visual cue where validation
									// errors have occurred
								}
							}
							break;
						case "reference_name":
							input = form.find(`[name='reference_name']`);
							if (lang === editLang) {
								// we're in the lang version for the
								// current edit form, so grab the input
								// value

								doc.reference_name = input.val();
							}
							if (!doc.reference_name) {
								valid = false;
								const msg = `The <strong>${reqHumanNames[index]}</strong> is missing from the <strong>${languages[lang]}</strong> version.`;
								if (errors[lang]) {
									errors[lang].push(msg);
								} else {
									errors[lang] = [msg];
								}
								if (editLang === lang) {
									input.parents(".field").addClass("error");
								}
							}
							break;
						default:
							input = form.find(`[name='${reqField}']`);
							if (lang === editLang) {
								// we're in the lang version for the
								// current edit form, so grab the input
								// value

								doc.langs[lang][reqField] = input.val();
							}
							if (!doc.langs[lang][reqField]) {
								valid = false;
								const msg = `The <strong>${reqHumanNames[index]}</strong> is missing from the <strong>${languages[lang]}</strong> version.`;
								if (errors[lang]) {
									errors[lang].push(msg);
								} else {
									errors[lang] = [msg];
								}
								if (editLang === lang) {
									input.parents(".field").addClass("error");
								}
							}
					}
				});
			}
			if (valid) {
				doc.autoList = [];
				$("[name='autoList']:checked").each(function () {
					doc.autoList.push($(this).val());
				});

				try {
					await cModal(`<h2>Are you sure?</h2>
					<div>
						Please confirm these values:
					</div>
					<p><span class="bold">Global document name:</span> ${doc.reference_name}</p>
					${doc.autoList && doc.autoList.length ? `<p><span class="bold">Page assignments:</span> ${doc.autoList.join(", ")}</p>` : ""}
					<ul class="uk-list">
						${Object.keys(doc.langs)
							.map(
								lang => `<li>
						<span class="bold">${languages[lang]}-language values:</span><br/>
						<span class="bold">Name:</span> ${doc.langs[lang].name}<br/>
						<span class="bold">Document preview:</span><br/>
						<iframe src="${doc.langs[lang].link}" class="uk-width-1-1 uk-margin-small-top" height="500px" allowfullscreen webkitallowfullscreen></iframe>
						</li>`
							)
							.join("")}
					</ul>`);
					const now = new Date().getTime();
					doc.updatedBy = author;
					doc.updated = now;
					doc.langs[editLang].updatedBy = author;
					doc.langs[editLang].updated = now;
					try {
						await request(
							{
								reqId: "4b9d14f5-1255-4f03-8b6a-da2363fe25ee",
								data: [
									{
										PutRequest: {
											Item: doc
										}
									}
								]
							},
							form
						);
						UIkit.modal(editor).hide();
						if (!id) {
							docs.push(doc);
						}
						manageDocs.sortDocs();
						manageDocs.listDocs(author);
					} catch (err) {
						throw err;
					}
				} catch (err) {
					if (err) {
						// check for err here because
						// modal cancellation throws
						// a silent error that should
						// be a no-op

						console.error("There was an error saving this document:", err);
						notify(`Error saving document`, "danger");
					}
				}
			} else {
				modal(`<div class="uk-modal-header">
					<div class="uk-modal-title">
						<h2>Cannot save document</h2>
					</div>
				</div>
				<div>
					<p>Please address the following issues and try to submit this document again:</p>
					<ul class="uk-list">
					${Object.keys(errors)
						.map(lang => `<li>${errors[lang].join("<br/>")}</li>`)
						.join("")}
					</ul>
				</div>`);
			}
		});
		// doc_edit_submit button click handler
	},
	// end editDoc method

	deleteDoc: async (author, id) => {
		const doc = docs.find(item => item.id === id);
		try {
			await cModal(`<h2>Are you sure?</h2>
			<div>You are about to <span class="bold">permanently</span> delete the document <span class="bold">${doc.reference_name}</span>. Please confirm below.`);
			try {
				const response = await request(
					{
						reqId: "f7ace6c3-dc2b-47f4-9f08-b890a61739ea",
						id: id,
						datatype: "pdf"
					},
					modalBody
				);
				docs = docs.filter(item => item.id != id);
				manageDocs.listDocs(author);
			} catch (err) {
				throw err;
			}
		} catch (err) {
			if (err) {
				console.error("There was an error deleting this document:", err);
				notify(`Error deleting document`, "danger");
			}
		}
	}
	// end deleteDoc method
};
// end manageDocs object
