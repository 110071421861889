import "./img/placeholder.jpg";
import "./img/logo-lockup-black.png";
const queries = new URLSearchParams(location.search);
let langAttr = $("html").attr("lang");

/* ************************
Exported vars
************************** */

export const api = "https://api.gulfstream.aero";
export let relLang = "en";
export const status = "prod";
// CHECK BEFORE DEPLOY!!!!

export const urlId = queries.has("id") ? queries.get("id") : null;
if (urlId && /\/news\/$/.test(location.pathname)) {
	relLang = langAttr != "zh-Hans" ? langAttr : "cn";
}
// special handling for news release requests, where we want English wrapper
// around original lang release if it was available

langAttr = "en";
$("html").attr("lang", "en");

export const cachedReleasesKey = status === "prod" ? "releases.json" : "releases-dev.json";
export const cachingServiceKeys = [`News Releases${status === "prod" ? "" : " Dev"}`, `Sustainability Releases${status === "prod" ? "" : " Dev"}`];
// these refer to the CachingService source references
export const table = status === "prod" ? "newsroom" : "newsroom-dev";
export const s3bucket = "gulfstreamnews-data";
export const appCode = "newsroom";
export const lang = langAttr != "zh-Hans" ? langAttr : "cn";
export const redirect = `${location.origin}/${lang}/`;
export const code = queries.has("code") ? queries.get("code") : null;
export const relay = queries.has("then") ? queries.get("then") : null;
export const sort = queries.has("sort") ? queries.get("sort") : "desc";
export let sortAttr = sort === "desc" ? "activePage" : "revActivePage";
export let adminSortAttr = sort === "desc" ? "grossPage" : "revGrossPage";
export const orderLink = queries.has("link") ? queries.get("link") : null;
export const perPage = 10;
export const pageNo = queries.has("page") ? parseInt(queries.get("page")) : 1;
const searchTermsRaw = queries.has("q")
	? queries
			.get("q")
			.split(";")
			.map(term => decodeURIComponent(term))
	: null;
export const searchTerms = searchTermsRaw && searchTermsRaw.length > 5 ? [searchTermsRaw.join(" ")] : searchTermsRaw;
export const searchYear = queries.has("year") ? queries.get("year") : null;
export const searchTag = queries.has("tag") ? queries.get("tag") : null;
export const searchDark = queries.has("dark") ? true : null;
export const view = queries.has("view") ? queries.get("view") : null;
export const msgWrapper = $("#messages");
export const assetHost = "https://d36vpv0zv8va20.cloudfront.net";
// this host points to CloudFront distro that pulls
// from origin group including the us-east-1 and
// its replicated us-east-2 counterpart

export const hasHostPatt = new RegExp(/^http?s:\/\//i);
export const s3HostPatt = new RegExp(/^http.*(amazonaws.com|gulfstreamnews-data)\//);
// host pattern accounts for primary and failover s3
// data bucket locations

export const placeholder = "/dist/img/placeholder.jpg";
export const logo = "/dist/img/logo-lockup-black.png";
export const languages = {
	en: "English",
	cn: "Chinese",
	pt: "Portuguese",
	es: "Spanish",
	ru: "Russian"
};
export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const metaFields = [
	{
		model: "Models"
	},
	{
		events: "Events"
	},
	{
		newsroomCategory: "Other Categories"
	},
	{
		prodsupp: "Customer Support"
	},
	{
		facilities: "Facilities"
	},
	{
		sites: "Sites"
	}
];
// what fields do we care about?
